import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import portal_config from 'assets/json/portal_config.json';
import TrangChu from 'pages/TrangChu';
import DangNhap from 'pages/DangNhap';
import DangKy from 'pages/DangKy';
import GioiThieu from 'pages/GioiThieu';
import ChinhDangChayBo from 'pages/ChinhDangChayBo';
import HoTro from 'pages/HoTro';
import KhoaHoc from 'pages/KhoaHoc';
import KhoaHocChiTiet from 'pages/KhoaHoc/ChiTiet';
import HocNgay from 'pages/KhoaHoc/HocChinh';
import KhoaHocThu from 'pages/KhoaHoc/HocThu';
import KienThuc from 'pages/KienThuc';
import ThanhToan from 'pages/ThanhToan';
import GioHang from 'pages/GioHang';
import TinTuc from 'pages/TinTuc';
import CaNhan from 'pages/CaNhan';
import TimKiem from 'pages/TimKiem';
import KhoaHocCaNhan from 'pages/KhoaHocCaNhan';
import ThanhTichHocVien from 'pages/ThanhTichHocVien';
import ChungChi from 'pages/ChungChi';
import XemTinTuc from 'pages/XemTinTuc';
import ChatBot from 'pages/ChatBot';
import QuenMatKhau from 'pages/QuenMatKhau';
import TimLaiMatKhau from 'pages/TimLaiMatKhau';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  const LayoutPortalPaths = [];
  const GetPage = code => {
    switch (code) {
      case 'TrangChu':
        return <TrangChu />;
      case 'ChungChi':
        return <ChungChi />;
      case 'GioiThieu':
        return <GioiThieu />;
      case 'KienThuc':
        return <KienThuc />;
      case 'KhoaHocCaNhan':
        return <KhoaHocCaNhan />;
      case 'ChinhDangChayBo':
        return <ChinhDangChayBo />;
      case 'CaNhan':
        return <CaNhan />;
      case 'HoTro':
        return <HoTro />;
      case 'TimKiem':
        return <TimKiem />;
      case 'KhoaHoc':
        return <KhoaHoc />;
      case 'HocNgay':
        return <HocNgay />;
      case 'KhoaHocChiTiet':
        return <KhoaHocChiTiet />;
      case 'KhoaHocThu':
        return <KhoaHocThu />;
      case 'ThanhToan':
        return <ThanhToan />;
      case 'GioHang':
        return <GioHang />;
      case 'DangNhap':
        return <DangNhap />;
      case 'DangKy':
        return <DangKy />;
      case 'ChiTietTinTuc':
        return <TinTuc />;
      case 'XemTinTuc':
        return <XemTinTuc />;
      case 'ThanhTichHocVien':
        return <ThanhTichHocVien />;
      case 'QuenMatKhau':
        return <QuenMatKhau />;
      case 'TimLaiMatKhau':
        return <TimLaiMatKhau />;
      default:
        return <></>;
    }
  };
  const IsRouteOfUser = route => {
    return false;
  };
  const RoutesRender = isLayout => {
    let routesHtml = [];
    let routesConfig = portal_config.routes;
    for (let i = 0; i < routesConfig.length; i++) {
      if (!IsRouteOfUser(routesConfig[i]) && routesConfig[i].isMenu) {
        continue;
      }
      if (isLayout && isLayout == routesConfig[i].isLayout) {
        LayoutPortalPaths.push(routesConfig[i].url);
        routesHtml.push(
          <Route
            key={routesConfig[i].code}
            path={routesConfig[i].url}
            component={() => GetPage(routesConfig[i].code)}
          />
        );
      }
      if (!isLayout && isLayout == routesConfig[i].isLayout) {
        routesHtml.push(
          <Route
            key={routesConfig[i].code}
            path={routesConfig[i].url}
            component={() => GetPage(routesConfig[i].code)}
          />
        );
      }
    }

    return routesHtml;
  };
  const OpenPhone = () => {
    window.open('tel:0916582783');
  };
  return (
    <div>
      <Route path={LayoutPortalPaths}>
        <MainLayout>
          <Switch>{RoutesRender(true)}</Switch>
        </MainLayout>
      </Route>
      {RoutesRender(false)}
      <a className="gs-btn-hotline gs-btn-fb-position" onClick={OpenPhone}>
        <i className="bi bi-telephone"></i>
      </a>
      <ChatBot />
    </div>
  );
};

export default Layout;

import React, {  useEffect } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { Actions } from "reducers/appReducer/Action";

const MainLayout = ({ children, LoadTrangChuConfig }) => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    LoadTrangChuConfig();
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />      
      {children}    
      <Footer />  
    </>
  );
};

const mapState = ({ ...state }) => ({

});
const mapDispatchToProps = {
  LoadTrangChuConfig: Actions.LoadTrangChuConfig
};

export default connect(mapState, mapDispatchToProps)(MainLayout);